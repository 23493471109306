@charset "UTF-8";
/* index */
/* index_common */
body {
  padding-top: 0; }

/*footer トップページだけの特別処理*/
footer {
  position: relative;
  z-index: 12;
  background: white; }

/* index_hero */
#overview {
  width: 100%;
  height: calc(180vh + 1400px);
  position: relative;
  z-index: 1;
  overflow: hidden; }
  #overview #hero {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100vw;
    height: auto;
    min-height: calc(180vh + 1400px); }
    #overview #hero #back_image {
      position: fixed;
      z-index: 5;
      top: 0;
      left: 0;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      background: url(../_images/top/hero_full3.jpg);
      background-size: cover;
      background-position: center; }
    #overview #hero #mainvisual.loading > #loader {
      display: none;
      color: #fff;
      display: block;
      position: fixed;
      width: 100%;
      height: 100%;
      z-index: 999999;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding-top: 180px;
      box-sizing: border-box;
      background-color: rgba(0, 0, 0, 0.7);
      background-image: url(../_images/top/loader_2.gif);
      background-size: auto;
      background-repeat: no-repeat;
      background-position: center center;
      text-align: center; }
    #overview #hero #mainvisual {
      z-index: 100; }
      #overview #hero #mainvisual .main-catch {
        position: absolute;
        z-index: 110;
        top: 40vh;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0; }
      #overview #hero #mainvisual .main-logo {
        position: absolute;
        z-index: 111;
        top: 45vh;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0; }
      #overview #hero #mainvisual .arrow {
        position: absolute;
        z-index: 112;
        top: 90vh;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0; }
      #overview #hero #mainvisual .main-text {
        position: absolute;
        z-index: 113;
        top: 155vh;
        left: 50%;
        transform: translateX(-50%);
        width: 40vh; }
  #overview #hero_cover {
    background: black;
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 90;
    top: 0;
    transition-property: all;
    transition-duration: .5s; }
  #overview #hero_cover.darken {
    opacity: .5; }

@media (max-width: 735.98px) {
  /*スマホだけ調整*/
  #overview {
    height: 300vh; }
    #overview #hero {
      min-height: 300vh; }
      #overview #hero #back_image {
        background-position: 60% center; }
      #overview #hero #mainvisual .main-catch {
        top: 40vh;
        max-width: 250px; }
      #overview #hero #mainvisual .main-logo {
        top: 40vh;
        width: 24.375%;
        max-width: 130px; }
      #overview #hero #mainvisual .arrow {
        /*スマホだけ調整*/
        top: 78vh;
        width: 27px; }
      #overview #hero #mainvisual .main-text {
        /*スマホだけ調整*/
        top: calc($mainTextTop - 5vh);
        width: 90.625vw;
        max-width: 320px; } }

@media (min-width: 736px) {
  #overview {
    height: calc("180vh + 1100px"); }
    #overview #hero {
      min-height: calc("180vh + 1100px"); }
      #overview #hero #mainvisual .main-catch {
        top: 40vh;
        max-width: 250px; }
      #overview #hero #mainvisual .main-logo {
        top: 45vh;
        max-width: 130px; }
      #overview #hero #mainvisual .arrow {
        top: 90vh; }
        #overview #hero #mainvisual .arrow > img {
          width: 27px; }
      #overview #hero #mainvisual .main-text {
        top: 155vh;
        width: 404px; } }

@media (min-width: 1000px) {
  #overview {
    height: calc(180vh + 1400px); }
    #overview #hero {
      min-height: calc(180vh + 1400px); }
      #overview #hero #mainvisual .main-catch {
        top: 40vh; }
      #overview #hero #mainvisual .main-logo {
        top: 45vh; }
      #overview #hero #mainvisual .arrow {
        top: 90vh; }
      #overview #hero #mainvisual .main-text {
        top: 155vh;
        width: 504px; } }

@media (min-width: 1400px) {
  #overview {
    height: calc(180vh + 1400px); }
    #overview #hero {
      min-height: calc(180vh + 1400px); }
      #overview #hero #mainvisual .main-catch {
        top: 40vh; }
      #overview #hero #mainvisual .main-logo {
        top: 45vh; }
      #overview #hero #mainvisual .arrow {
        top: 90vh; }
      #overview #hero #mainvisual .main-text {
        top: 155vh;
        width: 504px; } }

/* index_concept */
h2.top-heading {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-top: 1px solid #e3e7ea;
  border-bottom: 1px solid #e3e7ea;
  margin-bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  z-index: 60; }
  @media (max-width: 735.98px) {
    h2.top-heading {
      height: 56px; }
      h2.top-heading img {
        width: 31.7188%;
        max-width: 151px; } }
  @media (min-width: 736px) {
    h2.top-heading {
      height: 70px;
      margin: 0 auto; }
      h2.top-heading img {
        width: 20%;
        max-width: 144px; } }
  @media (min-width: 1000px) {
    h2.top-heading h2.top-heading {
      height: 80px;
      margin: 0 auto; }
      h2.top-heading h2.top-heading img {
        width: 14.4%;
        max-width: 144px; } }

#concept {
  position: relative;
  background: white;
  border-bottom: 1px solid #fff;
  top: 0;
  z-index: 10; }
  #concept article.concept-item {
    width: 100%; }

@media (max-width: 735.98px) {
  #concept article.concept-item {
    position: relative;
    z-index: 30; }
    #concept article.concept-item .concept-bg {
      display: none; }
    #concept article.concept-item .concept-visual {
      position: absolute;
      background-image: none;
      background-size: cover;
      background-repeat: no-repeat;
      height: 100vh;
      width: 100vw; }
      #concept article.concept-item .concept-visual#concept-visual-1 {
        background-image: url(../_images/top/ph_concept1-xs.jpg);
        background-position: 50% 72%; }
      #concept article.concept-item .concept-visual#concept-visual-2 {
        background-image: url(../_images/top/ph_concept2-xs.jpg);
        background-position: 50% 84%; }
      #concept article.concept-item .concept-visual#concept-visual-3 {
        background-image: url(../_images/top/ph_concept3-xs.jpg);
        background-position: 50% 81%; }
      #concept article.concept-item .concept-visual .con-txt-header {
        opacity: 0;
        text-align: center;
        position: absolute;
        z-index: 50;
        left: 0;
        right: 0; }
        #concept article.concept-item .concept-visual .con-txt-header img {
          height: 15.5454vh;
          width: auto; }
    #concept article.concept-item .con-txt-text {
      padding-top: 360vh;
      padding-bottom: 160vh;
      pointer-events: none;
      letter-spacing: 1px;
      line-height: 3.0;
      text-align: center; }
      #concept article.concept-item .con-txt-text span.text-break {
        display: block; }
      #concept article.concept-item .con-txt-text br {
        display: none; }
    #concept article.concept-item .btn-view, #concept article.concept-item .btn-view-close {
      display: none; } }

@media (min-width: 736px) {
  #concept article.concept-item {
    height: 600px;
    position: relative;
    z-index: 30; }
    #concept article.concept-item .concept-bg {
      background-image: none;
      background-size: cover;
      background-position: center top;
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
      position: absolute; }
    #concept article.concept-item #concept1_bg {
      background-image: url(../_images/top/ph_concept1-md.jpg);
      z-index: 31; }
    #concept article.concept-item #concept2_bg {
      background-image: url(../_images/top/ph_concept2-md.jpg);
      z-index: 32; }
    #concept article.concept-item #concept3_bg {
      background-image: url(../_images/top/ph_concept3-md.jpg);
      z-index: 33; }
    #concept article.concept-item > .cover-view {
      position: relative;
      width: 100%;
      height: 100%;
      height: 600px; }
      #concept article.concept-item > .cover-view .con-txt {
        display: block;
        height: 100%; }
        #concept article.concept-item > .cover-view .con-txt h2.con-txt-header {
          position: absolute;
          max-width: 348px;
          opacity: 0;
          z-index: 35; }
        #concept article.concept-item > .cover-view .con-txt .con-txt-text {
          text-align: left;
          position: absolute;
          line-height: 2.0;
          font-size: 1.4rem;
          opacity: 0;
          z-index: 36; }
      #concept article.concept-item > .cover-view a.btn-view, #concept article.concept-item > .cover-view a.btn-view-close {
        display: none; }
  #concept #concept1 > .cover-view > .con-txt h2.con-txt-header {
    top: 23%;
    left: 7.8%; }
  #concept #concept1 > .cover-view > .con-txt .con-txt-text {
    top: 190px;
    left: 7.8%; }
  #concept #concept2 .cover-view .con-txt h2.con-txt-header {
    top: 23%;
    left: 50%; }
  #concept #concept2 .cover-view .con-txt .con-txt-text {
    top: 190px;
    left: 50%; }
  #concept #concept3 .cover-view .con-txt h2.con-txt-header {
    top: 23%;
    left: 10.4%; }
  #concept #concept3 .cover-view .con-txt .con-txt-text {
    top: 190px;
    left: 10.4%; } }

@media (min-width: 1000px) {
  #concept article.concept-item {
    height: 600px;
    padding-top: 0;
    position: relative; }
    #concept article.concept-item .concept-bg {
      background-image: none;
      background-size: cover;
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
      position: absolute; }
      #concept article.concept-item .concept-bg#concept1_bg {
        background-image: url(../_images/top/ph_concept1.jpg);
        background-position: center 5%; }
      #concept article.concept-item .concept-bg#concept2_bg {
        background-image: url(../_images/top/ph_concept2.jpg);
        background-position: center 60%; }
      #concept article.concept-item .concept-bg#concept3_bg {
        background-image: url(../_images/top/ph_concept3.jpg);
        background-position: center 70%; }
    #concept article.concept-item > .cover-view {
      width: 1000px;
      margin: 0 auto;
      position: relative; }
      #concept article.concept-item > .cover-view .con-txt {
        width: 100%; }
        #concept article.concept-item > .cover-view .con-txt .con-txt-header {
          opacity: 0;
          z-index: 35; }
        #concept article.concept-item > .cover-view .con-txt .con-txt-text {
          line-height: 2.2;
          font-size: 1.6rem;
          opacity: 0;
          z-index: 36; }
  #concept #concept1 > .cover-view > .con-txt h2.con-txt-header {
    top: 23%;
    left: 40px; }
  #concept #concept1 > .cover-view > .con-txt .con-txt-text {
    top: 200px;
    left: 40px; }
  #concept #concept2 .cover-view .con-txt h2.con-txt-header {
    top: 23%;
    left: 538px; }
  #concept #concept2 .cover-view .con-txt .con-txt-text {
    top: 200px;
    left: 538px; }
  #concept #concept3 .cover-view .con-txt h2.con-txt-header {
    top: 23%;
    left: 104px; }
  #concept #concept3 .cover-view .con-txt .con-txt-text {
    top: 200px;
    left: 104px; } }

@media screen and (max-width: 360px) {
  #concept article.concept-item .cover-view .con-txt .con-txt-text {
    font-size: 1.3rem; } }

/* index_products */
/* PRODUCTS LINK */
#products {
  position: relative;
  z-index: 11;
  background: white; }

ul.products-section {
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 0;
  padding-bottom: 13px;
  background: white; }
  ul.products-section li {
    position: relative;
    margin: 0;
    padding: 0; }
    ul.products-section li img {
      vertical-align: bottom; }
    ul.products-section li a > h2 {
      position: absolute;
      top: 35.4%;
      left: 50%;
      width: 65.578%;
      max-width: 285px;
      transform: translateX(-50%); }
    ul.products-section li a > p {
      position: absolute;
      top: 51.8%;
      left: 50%;
      width: 65.578%;
      max-width: 285px;
      transform: translateX(-50%); }

@media (min-width: 736px) {
  ul.products-section {
    width: 100%;
    /*max-width: 1400px;*/
    height: 360px;
    display: flex;
    margin: 0 auto;
    padding-bottom: 40px; }
    ul.products-section li {
      width: 50%; }
      ul.products-section li a {
        display: block;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0); }
        ul.products-section li a > h2 {
          top: 36%;
          width: 60.8%;
          width: 292px; }
        ul.products-section li a > p {
          top: 54.167%;
          width: 60.8%;
          width: 292px; }
      ul.products-section li a:hover {
        transition: .3s;
        background: rgba(255, 255, 255, 0.5); }
      ul.products-section li#view-haircare {
        background: url(../_images/top/ph_haircare.jpg) center center no-repeat;
        background-size: cover; }
      ul.products-section li#view-skincare {
        background: url(../_images/top/ph_skincare.jpg) center center no-repeat;
        background-size: cover; } }

@media (min-width: 1000px) {
  ul.products-section li a > h2 {
    top: 36%;
    width: 304px; }
  ul.products-section li a > p {
    top: 54.167%;
    width: 304px; } }
